import React from 'react'
import AppCarousel from '../../components/AppCarousel/AppCarousel';
import AppFooter from '../../components/AppFooter/AppFooter';

export default function Home() {
  return (
    <>
      <AppCarousel/>
      <AppFooter />
    </>
  );
}
