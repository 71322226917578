import React from "react";
import AppTask from "../../components/AppTask/AppTask";
import AppFooter from "../../components/AppFooter/AppFooter";

export default function Kanban() {
  return (
    <>
      <AppTask/>
      <AppFooter />
    </>
  );
}
