import React from "react";
import AppFooter from "../../components/AppFooter/AppFooter";
import AppContact from "../../components/AppContact/AppContact";

export default function Home() {
  return (
    <div>
      <AppContact />
      {/* <AppFooter /> */}
    </div>
  );
}
