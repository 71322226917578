import React from "react";
import AppFooter from "../../components/AppFooter/AppFooter";
import AppAbout from "../../components/AppAbout/AppAbout";

export default function About() {
  return (
    <div>
      <AppAbout />
      <AppFooter />
      
    </div>
  );
}
